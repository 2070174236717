export default {
  'phone': 'Номер телефона',
  'call_history': 'История созвонов',
  'owner_status': 'Статус владельца',
  'description': 'Комментарий к статусу',
  'status': 'Статус телефона',
  'name': 'Имя',
  'sort_order': 'Порядок',
  'is_agent': 'Является агентом'
}
