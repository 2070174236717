import Vue from 'vue'

const timeout = 10000

export default {
  namespaced: true,
  state: {
    files: [],
    checkingFiles: false
  },
  mutations: {
    addFile (state, data) {
      state.files.unshift({ name: data, ready: false })
    },
    updateFiles (state, data) {
      state.files.forEach(file => {
        if (data.includes(file.name)) {
          file.ready = true
        }
      })
    },
    setCheckingFiles (state, data) {
      state.checkingFiles = data
    }
  },
  actions: {
    async download ({ getters, commit, dispatch }, { payload }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.post('downloader/' + payload.url, { data: payload.data })
        if (serverResponse.data.success) {
          commit('addFile', serverResponse.data.data)
          response = serverResponse.data.data
          dispatch('systemMessages/success', 'Выгрузка поставлена в очередь', { root: true })

          if (!getters['isChecking']) {
            setTimeout(() => dispatch('check'), timeout)
          }
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return response
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        return response
      }
    },
    async checkFilesForDownload ({ commit, dispatch }, { data }) {
      try {
        let response = false
        const serverResponse = await Vue.axios.post('/downloader/check', { data })
        if (serverResponse.data.success) {
          commit('updateFiles', serverResponse.data.data)
          response = true
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return response
      } catch (e) {
        console.log(e)
        dispatch('systemMessages/handleError', e, { root: true })
        return false
      }
    },
    async check ({ getters, commit, dispatch }) {
      const processingFiles = getters['getProcessingFiles']
      commit('setCheckingFiles', true)
      if (processingFiles.length) {
        const response = await dispatch('checkFilesForDownload', { data: processingFiles })
        if (processingFiles.length && response) {
          setTimeout(async () => dispatch('check'), timeout)
        }
      }
      commit('setCheckingFiles', false)
    }
  },
  getters: {
    getAllFiles (state) {
      return state.files
    },
    getProcessingFiles (state) {
      return state.files.filter(file => !file.ready)
    },
    isChecking (state) {
      return state.checkingDelete
    },
    getFileByName: (state) => (name) => {
      return state.files.find((file) => file.name === name)
    }
  }
}
