export default {
  'id': '#',
  'enter': 'Вход',
  'exit': 'Выход',
  'search': 'Поиск',
  'create': 'Создать',
  'save': 'Сохранить',
  'cancel': 'Отменить',
  'delete': 'Удалить',
  'close': 'Закрыть',
  'add': 'Добавить',
  'list': 'Список',
  'addList': 'Добавить списком',
  'export': 'Экспорт',
  'import': 'Импорт',
  'yes': 'Да',
  'no': 'Нет',
  'ok': 'Ок',
  'choose': 'Выбрать',
  'chooseFile': 'Выбрать файл',
  'done': 'Готово',
  'edit': 'Редактировать',
  'info': 'Информация',
  'menu': 'Меню',
  'confirm': 'Подтвердить',
  'sendConfirm': 'Отправить на подтверждение',
  'actions': 'Действия',
  'value': 'Значение',
  'attention': 'Внимание',
  'createAttr': 'Создать атрибут',
  'download': 'Загрузить',
  'createReport': 'Создать Отчет',
  'coordinates': 'Координаты',
  'created_at': 'Дата создания',
  'updated_at': 'Дата касания',
  'name': 'Название',
  'description': 'Описание',
  'category': 'Категория',
  'is_main': 'Основной',
  'status': 'Статус',
  'user_id': 'Пользователь',
  'parent_id': 'Родитель',
  'type': 'Тип',
  'comment': 'Коментарий',
  'source': 'Источник'
}
