export default {
  state: {
    copiedArray: []
  },
  mutations: {
    SET_COPIED_ARRAY (state, payload) {
      state.copiedArray = payload
    },
    CLEAR_COPIED_ARRAY (state) {
      state.copiedArray = []
    }
  },
  actions: {
    copyArray ({ commit }, array) {
      commit('SET_COPIED_ARRAY', array)
    },
    clearCopiedArray ({ commit }) {
      commit('CLEAR_COPIED_ARRAY')
    }
  },
  getters: {
    getCopiedArray: state => state.copiedArray
  }
}
