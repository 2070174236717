<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'
import AdminLayout from '@/layouts/AdminLayout'

export default {
  computed: {
    layout () {
      return (this.$route.meta.layout || 'auth') + '-layout'
    }
  },
  components: { AuthLayout, AdminLayout }
}
</script>

<style lang="stylus">
  #app
    font-family 'Avenir', Helvetica, Arial, sans-serif
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale
    color #2c3e50

    .v-data-table td {
      height: 33px;
      font-size 12px;
    }

    .tableListAdvert td, .tableListAdvert th {
      padding: 0px 3px;
      width: 50px !important;
      overflow: hidden;
    }

    .tableListAdvert th span {
      font-size: 11px;
      text-align: center
    }

    .sizePhotoBuilding {
      position: relative;
      padding: 15px;
      overflow: hidden;
      height: 200px;
      width 200px;
    }

    .sizePhotoBuilding img {
      height: auto;
      width auto;
    }

  /*note: костыль для корректной работы лейблов в инпутах вьютифая с атрибутом outlined*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }

  .v-text-field input, .v-select__selections, .v-application p, .v-textarea textarea {
    font-size 14px;

  }
</style>
