import _ from 'lodash'

// Полное удаление реактивности
export function cleanClone (obj) {
  return obj ? _.cloneDeep(obj) : obj
}

// Проверяем равенство массивов и объектов
export function deepEqual (entity1, entity2) {
  return _.isEqual(entity1, entity2)
}

export function fillObject (object, data) {
  for (let key in object) {
    if (object.hasOwnProperty(key) && data[key] !== undefined) {
      object[key] = data[key]
    }
  }
  return object
}

// доступ к свойствам объекта obj через строку path типа 'prop1.prop2.prop3'
export function recLookup (obj, path) {
  const parts = path.split('.')
  if (parts.length === 1) {
    return obj ? obj[parts[0]] : null
  }
  return recLookup(obj.hasOwnProperty(parts[0]) ? obj[parts[0]] : null, parts.slice(1).join('.'))
}

export function maskValue (value, pattern) {
  if (value) {
    let i = 1
    let v = value.toString()
    return pattern.replace(/#/g, _ => v[i++])
  }
}

export function processGetData (rawData) {
  let data = {
    pagination: {
      pageNumber: rawData.options.page,
      perPage: rawData.options.itemsPerPage
    }
  }

  if (rawData.filters) {
    data.filter = {}
    for (let key in rawData.filters) {
      if (rawData.filters.hasOwnProperty(key)) {
        if (Array.isArray(rawData.filters[key]) && rawData.filters[key].length && !deepEqual(rawData.filters[key], [null, null])) {
          data.filter[key] = rawData.filters[key]
        } else if (rawData.filters[key] !== null) {
          if (typeof rawData.filters[key] === 'object') {
            if (rawData.filters[key].hasOwnProperty('id')) {
              data.filter[key.replace('Filter', 'Id')] = rawData.filters[key].id
            } else {
              data.filter[key] = rawData.filters[key]
            }
          } else {
            data.filter[key] = rawData.filters[key]
          }
        }
      }
    }
  }

  if (rawData.options.sortBy.length) {
    data.sort = {}
    rawData.options.sortBy.forEach((item, index) => {
      data.sort[item] = rawData.options.sortDesc[index] ? 'DESC' : 'ASC'
    })
  }
  return data
}
