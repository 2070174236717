import router from '@/router'
import store from '@/store'

export default function () {
  return collectMenuItems(menuConfig)
}

const menuConfig = [
  {
    menuItem: 'advert',
    role: null
  },
  {
    menuItem: 'phone',
    role: 'admin'
  },
  {
    menuItem: 'user',
    role: 'admin'
  },
  {
    menuItem: 'cms_page',
    role: 'admin'
  },
  {
    menuItem: 'log',
    role: 'admin'
  },
  {
    menuItem: 'clients',
    role: null
  },
  {
    title: 'ЗАГРУЗКА/ВЫГРУЗКА',
    role: 'admin',
    menuItem: null,
    subitems: [
      {
        menuItem: 'export',
        role: 'admin'
      },
      {
        menuItem: 'import',
        role: 'admin'
      }
    ]
  },
  {
    title: 'СПРАВОЧНИКИ',
    role: null,
    menuItem: null,
    subitems: [
      {
        menuItem: 'building',
        role: null
      },
      {
        menuItem: 'street',
        role: 'admin'

      },
      {
        menuItem: 'service-options',
        role: 'admin'
      }
    ]
  }
]

function collectMenuItems (menuConfig) {
  let menu = []
  menuConfig.forEach(menuConfigItem => {
    if (!menuConfigItem.role || menuConfigItem.role === store.getters['auth/getRole']) {
      let menuItem = {}
      if (menuConfigItem.menuItem) {
        const route = router.resolve({ name: menuConfigItem.menuItem })
        if (route) {
          menuItem = {
            title: route.route.meta.title,
            icon: route.route.meta.icon,
            link: route.route.fullPath
          }
        }
      } else {
        menuItem = {
          title: menuConfigItem.title,
          subitems: collectMenuItems(menuConfigItem.subitems)
        }
      }
      menu.push(menuItem)
    }
  })
  return menu
}
