import Vue from 'vue'
import { processGetData } from '@/components/utils/common'

const timeout = 5 * 60 * 1000

export default {
  namespaced: true,
  state: {
    filterDefaults: null,
    unverifiedAdverts: 0
  },
  mutations: {
    setFilterDefaults (state, data) {
      state.filterDefaults = data
    },
    setUnverifiedAdverts (state, data) {
      state.unverifiedAdverts = data * 1
    }
  },
  actions: {
    async checkUnverified ({ dispatch }) {
      if (await dispatch('getUnverified')) {
        setTimeout(async () => dispatch('checkUnverified'), timeout)
      }
    },
    async getUnverified ({ commit, dispatch }) {
      let response = true
      try {
        const serverResponse = await Vue.axios.get('/advert/unverified')
        if (serverResponse.data.success) {
          commit('setUnverifiedAdverts', serverResponse.data.data)
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        response = false
      }
      return response
    },
    async fillTable ({ dispatch }, { url, rawData }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.get(url, { params: processGetData(rawData) })
        if (serverResponse.data.success) {
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return response
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        return response
      }
    },
    async autocomplete ({ dispatch }, { url, queryString }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.get(url + `?q=${queryString}`)
        if (serverResponse.data.success) {
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return response
      } catch (e) {
        console.log(e)
        dispatch('systemMessages/handleError', e, { root: true })
        return response
      }
    },
    async getServerData ({ dispatch }, { url }) {
      let response = false
      try {
        const serverResponse = await Vue.axios.get(url)
        if (serverResponse.data.success) {
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async deleteGroup ({ dispatch }, { url, data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.delete(url, { params: data })
        if (serverResponse.data.success) {
          if (serverResponse.data.data) {
            dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
          }
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return response
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        return response
      }
    },
    async getFilterDefaults ({ getters, commit, dispatch }, { url }) {
      let filters = getters['getFilterDefaults']
      if (!filters) {
        filters = await dispatch('getServerData', { url })
        if (filters) {
          commit('setFilterDefaults', filters)
        }
      }
      return filters
    }
  },
  getters: {
    getFilterDefaults: state => state.filterDefaults,
    getUnverifiedAdverts: state => state.unverifiedAdverts
  }
}
