<template>

  <v-container
    class="fill-height"
    fluid
  >
    <MessageSnack
      class="top-modifier"
      v-for="(message, index) in messages"
      :key="index"
      :message="message"
      :message-index="index"
    />
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
      >
        <router-view/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MessageSnack from '@/components/layouts/admin/MessageSnack'

export default {
  name: 'auth-layout',
  components: { MessageSnack },
  computed: {
    messages () {
      return this.$store.getters['systemMessages/getMessages']
    }
  }
}
</script>
<style>
  .fill-height {
    background: url("~@/assets/bg.jpg") center/cover;
    z-index: 1;
  }
</style>
