<template>
  <v-snackbar
    class="top-modifier"
    v-model="localShow"
    :color="message.type"
    :timeout="timeout"
    right
    top
  >
    {{text}}
    <v-btn
      dark
      text
      @click="hideMessage"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'MessageSnack',
  props: {
    message: Object,
    messageIndex: Number
  },
  data: function () {
    return {
      timeout: 2000,
      localShow: true
    }
  },
  computed: {
    text () {
      return this.message.text
    }
  },
  watch: {
    localShow () {
      this.hideMessage()
    }
  },
  methods: {
    hideMessage () {
      this.$store.commit('systemMessages/hideMessage', this.messageIndex)
    }
  }
}
</script>
