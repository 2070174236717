import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from '@/store'
import Qs from 'qs'
import * as Config from '@/config'

const axiosInstance = axios.create({
  baseURL: Config.baseUrl,
  timeout: Config.baseTimeout,
  headers: {
    'Authorization': store.getters['auth/getJwt'],
    'Access-Control-Allow-Origin': '*'
  }
})

// конфиг для адекватной сериализации объекта в GET-параметры
axiosInstance.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return Qs.stringify(params, {
      arrayFormat: 'indices',
      encode: false
    })
  }

  return config
})

Vue.use(VueAxios, axiosInstance)

export default new VueAxios()
