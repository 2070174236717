<template>
  <v-navigation-drawer
    :value="value"
    @input="$emit('input', $event)"
    fixed
    bottom
    collapse-on-scroll
    temporary
    class="pt-10 pr-3 pl-3"
  >
    <div class="text-left pt-10">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Категории
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list
        color="transparent"
      >
        <div
          v-for="(item,i) in getMenuItems"
          :key="i"
        >
          <v-list-item
            v-if="!item.subitems"
            :to="item.link"
            active-class="active"
            color=" "
            class="v-list-item"
            @click.stop="onclick"
            dense
          >
            <v-list-item-icon>
              <v-icon small color="primary">{{item.icon}}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title
                class="caption"
                v-text="item.title"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title
                  class="caption"
                  v-text="item.title"
                ></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="(subitem,i) in item.subitems"
              :key="i"
              :to="subitem.link"
              color=""
              @click="onclick"
              dense
              class="pl-10"
            >
              <v-icon
                class="mr-2"
                small
              >{{subitem.icon}}
              </v-icon>
              <v-list-item-title
                class="text-uppercase"
                v-text="subitem.title"
              ></v-list-item-title>
            </v-list-item>

          </v-list-group>
        </div>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<script>
import menuItems from './menuConfig'

export default {
  name: 'Navigation',
  props: {
    value: { type: Boolean }
  },
  computed: {
    getMenuItems () {
      return menuItems()
    }
  },
  methods: {
    onclick () {
      this.$emit('input', false)
    }
  }
}
</script>

<style scoped>
</style>
