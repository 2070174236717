class ClientsFilterList {
  constructor () {
    this.username = null
    this.whatsapp = null
    this.updatedAt = [null, null]
    this.createdAt = [null, null]
    this.parentId = null
    this.impression = null
    this.successRate = null
    this.clientStatus = null
    this.clientCategory = null
    this.dealStep = null
    this.strategy = 'all'
  }
}

export default ClientsFilterList
