import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)
Vue.use(store)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes
})

router.beforeEach(async (to, from, next) => {
  const isNeedAuth = to.matched.some(route => route.meta.auth)
  const isLogged = !!store.getters['auth/getJwt']
  if (isNeedAuth && !isLogged) {
    next('/login')
  } else {
    next()
  }
})

export default router
