export default {
  'username': 'Имя',
  'phone': 'Телефон',
  'email': 'E-mail',
  'online': 'Онлайн',
  'user': 'Пользователь',
  'active': 'Активен',
  'resetPassword': 'Сбросить пароль',
  'changePassword': 'Изменить пароль',
  'oldPassword': 'Старый пароль',
  'newPassword': 'Новый пароль',
  'repeatNewPassword': 'Повторить новый пароль',
  'position': 'Должность',
  'type': 'Тип пользователя',
  'profile': 'Данные профиля',
  'info': 'Информация о профиле',
  'searchReason': 'Параметры поиска',
  'whatsApp': 'Имя в WhatsApp',
  'paymentMethod': 'Способ оплаты',
  'successRate': 'Статус адекватности требований',
  'dealStep': 'Статус лояльности',
  'impression': 'Статус человека',
  'description': 'Комментарий',
  'address': 'Адрес проживания',
  'birthDate': 'День рождения',
  'clientStatus': 'Активный/Не активный',
  'delayUntil': 'Отложить до',
  'delayComment': 'Комментарий к "Отложить до"'
}
