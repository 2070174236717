import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async save ({ dispatch }, { url, data }) {
      let serverResponse = null
      try {
        serverResponse = await Vue.axios.post(url, data)
        if (serverResponse.data.success) {
          serverResponse = serverResponse.data.data
        } else {
          await dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return serverResponse
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        return serverResponse
      }
    },
    async remove ({ dispatch }, { url, data }) {
      let serverResponse = null
      try {
        serverResponse = await Vue.axios.delete('/admin/image', data)
        if (serverResponse.data.success) {
          serverResponse = serverResponse.data.data
        } else {
          await dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return serverResponse
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        return serverResponse
      }
    }
  }
}
