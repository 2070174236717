import { recLookup } from '@/components/utils/common'
import base from './ru/base'
import advert from './ru/advert'
import building from '@/messages/ru/building'
import buildingAddress from '@/messages/ru/buildingAddress'
import advertExtra from '@/messages/ru/advertExtra'
import advertPhone from '@/messages/ru/advertPhone'
import advertImage from '@/messages/ru/advertImage'
import phone from '@/messages/ru/phone'
import user from '@/messages/ru/user'

let ru = {
  base,
  advert,
  advertExtra,
  advertPhone,
  advertImage,
  building,
  buildingAddress,
  phone,
  user
}

export function translate (alias) {
  return recLookup(ru, alias)
}
