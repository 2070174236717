class AdvertFilterList {
  constructor () {
    this.touchDate = [null, null]
    this.rooms = [null, null]
    this.floor = [null, null]
    this.floors = [null, null]
    this.price = [null, null]
    this.areaCommon = [null, null]
    this.buildingYear = [null, null]
    this.streetFilter = null
    this.kitchenType = []
    this.districtCustom = null
    this.districtOfficial = null
    this.flatType = []
    this.buildingMaterial = null
    this.flatStatus = []
    this.buildingNumberFilter = null
    this.builder = []
    this.apartmentComplex = []
    this.isVerified = 1
    this.advStatus = 2
  }
}

export default AdvertFilterList
