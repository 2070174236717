import Vue from 'vue'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async fillDetail ({ dispatch }, { url }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.get(url)
        if (serverResponse.data.success) {
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return response
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        return response
      }
    },
    async save ({ dispatch }, { url, data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.post(url, { data })
        if (serverResponse.data.success) {
          response = serverResponse.data.data
          await dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
        } else {
          await dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async delete ({ dispatch }, { url, data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.delete(url, { params: data })
        if (serverResponse.data.success) {
          if (serverResponse.data.data) {
            dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
          }
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async checkSafeDelete ({ dispatch }, { url }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.get(url)
        if (serverResponse.data.success) {
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
        return response
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
        return response
      }
    }
  },
  getters: {}
}
