import Vue from 'vue'
import Tab from '@/components/structures/tab'
import { list } from '@/config'
import _ from 'lodash'

export default {
  namespaced: true,
  state: {
    tabs: {}
  },
  mutations: {
    add (state, { categoryName, type, data }) {
      if (!state.tabs.hasOwnProperty(categoryName)) {
        // используется для создания нового реактивного свойства!
        // обычное присвоение state.tabs.advert = {} не сработает
        Vue.set(state.tabs, categoryName, {
          activeTab: 0,
          data: []
        })
        type = list.value
      }
      // добавляем литерал объекта, а не дефолтную переменную, т.к. нужно, чтобы каждый элемент массива не отслеживался
      // если добавить в виде переменной, то изменения, произведенные в одном элементе массива будут происходить и во всех остальных
      // по свободе - попробовать использовать реактивность, полученную при добавлении через переменную, для модификации и отслеживания элементов без метода update
      let newTab = new Tab({ type, categoryName })

      if (data) {
        _.merge(newTab.data, data)
      }

      state.tabs[categoryName].data.push(newTab)
      state.tabs[categoryName].activeTab = state.tabs[categoryName].data.length - 1
    },
    remove (state, { categoryName, tabIndex }) {
      if (state.tabs.hasOwnProperty(categoryName)) {
        if (state.tabs[categoryName].activeTab >= tabIndex) {
          state.tabs[categoryName].activeTab--
        }
        state.tabs[categoryName].data.splice(tabIndex, 1)
      }
    },
    update (state, { categoryName, tabIndex, data }) {
      if (state.tabs[categoryName].data.length > tabIndex) {
        state.tabs[categoryName].data[tabIndex].data = { ...state.tabs[categoryName].data[tabIndex].data, ...data }
      }
    },
    setActiveTab (state, { categoryName, tabIndex }) {
      state.tabs[categoryName].activeTab = tabIndex
    }
  },
  actions: {
    async refresh ({ getters, commit, dispatch }, { categoryName, requestParams }) {
      try {
        return await Vue.axios.get(`/${categoryName}`, {
          data: requestParams
        }).data
      } catch (e) {
        console.log(e.response)
        dispatch('systemMessages/error', 'Не удалось обновить данные', { root: true })
        return null
      }
    }
  },
  getters: {
    byCategory: (state) => (category) => {
      if (state.tabs.hasOwnProperty(category)) {
        return state.tabs[category].data
      }
      return []
    },
    getActiveTab: (state) => (category) => {
      if (state.tabs.hasOwnProperty(category)) {
        return state.tabs[category].activeTab
      }
      return null
    },
    indexById: state => (category, id) => {
      let index = null
      if (state.tabs.hasOwnProperty(category)) {
        index = state.tabs[category].data.findIndex(item => item.data.id === id)
      }
      return index
    },
    indexByClientId: state => (category, id) => {
      let index = null
      if (state.tabs.hasOwnProperty(category)) {
        index = state.tabs[category].data.findIndex(item => item.type === 'detail' && item.data.requestParams && item.data.requestParams.id === id)
      }
      return index
    }
  }
}
