import Vue from 'vue'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    jwt: null,
    profile: null
  },
  mutations: {
    setJwt (state, jwt) {
      state.jwt = jwt
    },
    setProfile (state, data) {
      state.profile = data
    }
  },
  actions: {
    async login ({ getters, commit, dispatch }, credentials) {
      try {
        const response = await Vue.axios.post('/login', {
          data: credentials
        })
        if (response.data.success) {
          commit('setJwt', 'Bearer ' + response.data.data.jwt)
          commit('setProfile', response.data.data.profile)
          Vue.axios.defaults.headers['Authorization'] = getters['getJwt']
          dispatch('systemMessages/success', 'Вы успешно вошли в систему', { root: true })
          await router.push('/advert')
        } else {
          dispatch('systemMessages/error', response.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e.response)
        dispatch('systemMessages/handleError', 'Системная ошибка, повторите попытку позже', { root: true })
      }
    },
    async logout ({ commit, dispatch }) {
      let response = true
      try {
        response = await Vue.axios.get('/logout')
      } catch (e) {
        dispatch('systemMessages/handleError', 'Системная ошибка, повторите попытку позже', { root: true })
      }
      commit('setJwt', null)
      commit('setProfile', null)
      await router.push('/login')
      return response
    },
    async changePassword ({ dispatch }, { data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.post('/user/change-password', { data })
        if (serverResponse.data.success) {
          response = serverResponse.data.data
          dispatch('systemMessages/success', 'Пароль успешно изменен', { root: true })
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async isUnique ({ dispatch }, { data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.post('/user-identity/check-unique', { data })
        if (serverResponse.data.success) {
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async isUniqueUrl ({ dispatch }, { data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.post('/cms-page/check-unique', { data })
        if (serverResponse.data.success) {
          response = serverResponse.data.data
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async save ({ commit, dispatch }, { data }) {
      let response = null
      try {
        const serverResponse = await Vue.axios.post('/user/profile', { data })
        if (serverResponse.data.success) {
          response = serverResponse.data.data
          commit('setProfile', response)
          dispatch('systemMessages/success', 'Данные успешно сохранены', { root: true })
        } else {
          dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
        }
      } catch (e) {
        console.log(e)
        await dispatch('systemMessages/handleError', e, { root: true })
      }
      return response
    },
    async fillProfile ({ getters, commit, dispatch }) {
      let response = getters['getProfile']
      if (!response) {
        try {
          const serverResponse = await Vue.axios.get('user/profile')
          if (serverResponse.data.success) {
            response = serverResponse.data.success
            commit('setProfile', serverResponse.data.data)
          } else {
            dispatch('systemMessages/error', serverResponse.data.errors, { root: true })
          }
        } catch (e) {
          await dispatch('systemMessages/handleError', e, { root: true })
        }
      }
      return response
    }
  },
  getters: {
    getJwt (state) {
      return state.jwt
    },
    getName (state) {
      return state.profile ? state.profile.username : state.profile
    },
    getRole: state => state.profile ? state.profile.role : state.profile,
    getProfile (state) {
      return state.profile
    },
    can: state => (role, name = null) => {
      let result = false
      if (state.profile) {
        if (state.profile.role === 'admin') {
          result = true
        } else if (state.profile.role === role) {
          if (!name || (name && state.profile.customPermissions.some(permission => permission === name))) {
            result = true
          }
        }
      }

      return result
    }
  }
}
