export default [
  {
    path: '/export',
    name: 'export',
    meta: {
      title: 'ВЫГРУЗКА ИЗ БАЗЫ',
      layout: 'admin',
      auth: true,
      icon: 'mdi-cloud-download-outline'
    },
    component: () => import('@/views/admin/Export.vue')
  },
  {
    path: '/import',
    name: 'import',
    meta: {
      title: 'ЗАГРУЗКА В БАЗУ',
      layout: 'admin',
      auth: true,
      icon: 'mdi-cloud-upload-outline'
    },
    component: () => import('@/views/admin/Parser.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'auth',
      auth: false
    },
    component: () => import('@/views/auth/Login.vue')
  },
  {
    path: '/profile',
    name: 'profile',
    meta: {
      layout: 'admin',
      auth: true,
      icon: 'mdi-account'
    },
    component: () => import('@/views/admin/Profile.vue')
  },
  {
    path: '',
    redirect: { name: 'advert' }
    /*name: 'dashboard',
    meta: {
      layout: 'auth',
      auth: true
    },
    component: () => import('@/components/views/admin/dashboard/Dashboard.vue')*/
  },
  {
    path: '/service-options',
    name: 'service-options',
    meta: {
      title: 'Сервисные опции',
      layout: 'admin',
      auth: true
    },
    component: () => import('@/views/admin/ServiceOptions.vue')
  },
  {
    path: '/',
    meta: {
      layout: 'admin',
      auth: true
    },
    component: () => import('@/views/admin/TabsContainer.vue'),
    children: [
      {
        path: 'advert',
        name: 'advert',
        meta: {
          title: 'КАТАЛОГ ОБЪЯВЛЕНИЙ',
          layout: 'admin',
          auth: true,
          icon: 'mdi-table'
        },
        components: {
          detail: () => import('@/components/views/admin/advert/Detail'),
          list: () => import('@/components/views/admin/advert/List'),
          file: () => import('@/components/views/admin/advert/File')
        }
      },
      {
        path: 'phone',
        name: 'phone',
        meta: {
          title: 'ТЕЛЕФОНЫ',
          layout: 'admin',
          auth: true,
          icon: 'mdi-phone'
        },
        components: {
          detail: () => import('@/components/views/admin/phone/Detail'),
          list: () => import('@/components/views/admin/phone/List')
        }
      },
      {
        path: 'user',
        name: 'user',
        meta: {
          title: 'АГЕНТЫ',
          layout: 'admin',
          auth: true,
          icon: 'mdi-account-multiple'
        },
        components: {
          detail: () => import('@/components/views/admin/user/Detail'),
          list: () => import('@/components/views/admin/user/List')
        }
      },
      {
        path: 'clients',
        name: 'clients',
        meta: {
          title: 'КЛИЕНТЫ',
          layout: 'admin',
          auth: true,
          icon: 'mdi-account-cash'
        },
        components: {
          detail: () => import('@/components/views/admin/clients/Detail'),
          list: () => import('@/components/views/admin/clients/List')
        }
      },
      {
        path: 'building',
        name: 'building',
        meta: {
          title: 'Дома',
          layout: 'admin',
          auth: true,
          icon: ' '
        },
        components: {
          detail: () => import('@/components/views/admin/building/Detail'),
          list: () => import('@/components/views/admin/building/List')
        }
      },
      {
        path: 'street',
        name: 'street',
        meta: {
          title: 'Улицы',
          layout: 'admin',
          auth: true
        },
        components: {
          detail: () => import('@/components/views/admin/street/Detail'),
          list: () => import('@/components/views/admin/street/List')
        }
      },
      {
        path: '/log',
        name: 'log',
        meta: {
          title: 'ЛОГ',
          layout: 'admin',
          auth: true,
          icon: 'mdi-format-list-text'
        },
        components: {
          list: () => import('@/components/views/admin/log/List'),
          detail: () => import('@/components/views/admin/log/Detail')
        }
      },
      {
        path: '/cms-page',
        name: 'cms_page',
        meta: {
          title: 'SEO-ПУБЛИКАТОР',
          layout: 'admin',
          auth: true,
          icon: 'mdi-web'
        },
        components: {
          detail: () => import('@/components/views/admin/cms/Detail'),
          list: () => import('@/components/views/admin/cms/List')
        }
      }
    ]
  }
]
