import FiltersFactory from '@/components/structures/filters/filtersFactory'

class Tab {
  constructor (props) {
    this.type = props.type
    this.key = Date.now()
    this.data = {
      model: null,
      id: null,
      title: null,
      client_id: null,
      agent_id: null,
      requestParams: {
        filters: new FiltersFactory({ module: props.categoryName }),
        options: {}
      }
    }
  }
}

export default Tab
