import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import auth from './auth'
import systemMessages from './systemMessages'
import tab from './tab'
import table from './table'
import serviceData from './serviceData'
import detail from './detail'
import imageUploader from './imageUploader'
import download from './download'
import copyAdvertExternal from './copyAdvertExternal'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      name: 'mercury',
      paths: ['auth.jwt', 'tab.tabs']/*,
      storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, {
          // secure: true, //todo: улучшить систему защиты, использовать jwt через защищенные куки
          // httpOnly: true,
          sameSite: 'strict'
        }),
        removeItem: key => Cookies.remove(key)
      }*/
    })
  ],
  modules: {
    auth,
    systemMessages,
    tab,
    table,
    serviceData,
    detail,
    imageUploader,
    download,
    copyAdvertExternal
  }
})
