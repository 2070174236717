export default {
  lider_id: 'Ид в базе Лидер',
  source: 'Источник',
  user_in_charge: 'Ответственный',
  building_id: 'Строение',
  owner_id: 'Владелец',
  owner_status_local: 'Статус владельца',
  adv_status: 'Статус объявления',
  adv_type: 'Тип объявления',
  flat_type: 'Тип жилья',
  flat_number: 'Номер квартиры',
  room_count: 'Кол. комнат',
  floor: 'Этаж',
  flat_status: 'Состояние',
  price: 'Цена т.р',
  price_history: 'История цены',
  area_common: 'Общая площадь',
  area_living: 'Жилая',
  area_kitchen: 'Кухня',
  kitchen_type: 'Планировка',
  description_default: 'Описание',
  description_public: 'Описание для публички',
  description_service: 'Служебное описание квартиры и продавца',
  is_readonly: 'Только для чтения',
  is_published: 'Опубликовано',
  is_verified: 'Подтверждено',
  is_editing: 'Поднимать в выдаче',
  exclusive_status: 'Эксклюзив статус',
  published_at: 'Дата публикации',
  advertExtra: 'Экстра статус',
  published_by: 'Опубликовал',
  delay_until: 'Отложено до',
  burden: 'Обременение',
  hasBurden: 'Есть обременение',
  raw_data: 'Исходные данные',
  delay_comment: 'Коментарий',
  'created_at': 'Дата создания',
  'updated_at': 'Дата касания'
}
