export default {
  'district_official': 'Район общегородской',
  'district_custom': 'Район',
  'building_type': 'Тип здания',
  'building_material': 'Мат. стен',
  'total_floors': 'Этажность',
  'total_apartments': 'Всего квартир',
  'building_serial': 'Серийный номер здания',
  'front_doors_count': 'Всего подъездов',
  'overhaul': 'Кап ремонт',
  'build_year': 'Год постр.',
  'builder': 'Застройщик',
  'apartment_complex': 'ЖК',
  'manager_company': 'Управляющая компания',
  'description_service': 'Служебное описание',
  'description_entrance': 'Правильный подход к дому',
  'building_number': 'Номер дома',
  'subdistrict': 'Подрайон',
  'is_ready': 'Сдан'
}
