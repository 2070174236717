<template>
  <div>
    <MessageSnack
      class="top-modifier"
      v-for="(message, index) in messages"
      :key="index"
      :message="message"
      :message-index="index"
    />
    <HeaderBlock/>
    <v-main>
      <v-container
        fluid
        class="pa-5"
      >
        <v-row>
          <v-col cols="12">
            <router-view/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MessageSnack from '@/components/layouts/admin/MessageSnack'
import HeaderBlock from '@/components/layouts/admin/HeaderBlock'

export default {
  name: 'admin-layout',
  components: {
    HeaderBlock,
    MessageSnack
  },
  computed: {
    messages () {
      return this.$store.getters['systemMessages/getMessages']
    }
  },
  async mounted () {
    this.$store.dispatch('table/checkUnverified')
    await this.$store.dispatch('serviceData/optionValues')
    return this.$store.dispatch('auth/fillProfile')
  }
}
</script>

<style>
  .saveBlock {
    background: #fff;
    padding: 10px;
    position: fixed;
    right: 0px;
    top: 67px;
    z-index: 1;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  }
</style>
