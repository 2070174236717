import AdvertFilterList from '@/components/structures/filters/advertFilterList'
import ClientsFilterList from '@/components/structures/filters/clientsFilterList'

class FiltersFactory {
  constructor (props) {
    let model = {}
    switch (props.module) {
      case 'advert':
        model = new AdvertFilterList()
        break
      case 'clients':
        model = new ClientsFilterList()
        break
    }
    return model
  }
}

export default FiltersFactory
