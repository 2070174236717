import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import axios from '@/plugins/axios'
import vuetify from '@/plugins/vuetify'
import uploader from '@/plugins/uploader'
import clipboard from '@/plugins/clipboard'
import vuelidate from '@/plugins/vuelidate'
import DatetimePicker from 'vuetify-datetime-picker'
import { translate } from '@/messages'
import VueMask from 'v-mask'

Vue.config.productionTip = false
Vue.prototype.$t = translate
Vue.prototype.$can = store.getters['auth/can']

Vue.use(DatetimePicker)
Vue.use(VueMask)

new Vue({
  router,
  store,
  axios,
  vuetify,
  uploader,
  clipboard,
  vuelidate,
  render: h => h(App)
}).$mount('#app')
